import {RESET, SET_ANSWER, SET_NOTES, SET_PERSONAL_DATA, SET_RESULT_NOTES} from "../actions/answers";
import {getInitialAnswersState} from "../../utility/AnswersHelper";
import Debug from "../../utility/Debug";

const initialState = getInitialAnswersState();

export default function answersReducer(state = initialState, action) {
    let newState = {...state};

    switch (action.type) {
        case SET_ANSWER:
            Debug.trace(action.type, action, state);

            // TODO: Validation and Errorhandling
            newState.chapters[action.payload.chapterIndex].questions[action.payload.questionIndex].answer = action.payload.answer;

            return newState;

        case SET_NOTES:
            Debug.trace(action.type, action, state);

            // TODO: Validation and Errorhandling
            newState.chapters[action.payload.chapterIndex].questions[action.payload.questionIndex].notes = action.payload.notes;

            return newState;

        case SET_PERSONAL_DATA:
            Debug.trace(action.type, action, state);

            newState.personalData = {
                name: action.payload.name,
                date: action.payload.date
            };

            return newState;

        case SET_RESULT_NOTES:
            Debug.trace(action.type, action, state);

            newState.notes = action.payload.notes;

            return newState;

        case RESET:
            Debug.trace(action.type, action, state);

            return getInitialAnswersState();

        default:
            Debug.trace(action.type, state);

            return newState;
    }
}