import "./Title.sass";
import React from "react";
import PropTypes from "prop-types";

function Title(props) {
    const {title} = props;

    return (
        <div className="Title text-center flex-grow-1">
            <h2 className="text-uppercase">{title}</h2>
        </div>
    );
}

Title.propTypes = {
    title: PropTypes.string.isRequired,
}

export default Title;