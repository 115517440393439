import "./Home.sass";
import React from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {reset} from "../../redux/actions/answers";
import {Row, Col} from "react-bootstrap";
import Debug from "../../utility/Debug";
import {ROUTING_QUESTIONNAIRE, url} from "../../utility/Routing";
import Main from "../Page/Main/Main";
import Header from "../Page/Header/Header";
import Page from "../Page/Page";
import EnterButton from "../EnterButton/EnterButton";
import ReadMore from "../Page/ReadMore/ReadMore";

function Home() {
    Debug.info('Rendering component %s', 'Home');

    const dispatch = useDispatch();
    const history = useHistory();

    function handleStart() {
        dispatch(reset());
        history.push(url(ROUTING_QUESTIONNAIRE));
    }

    return (
        <Page name="Home">
            <Header homeButton={false} backLink={false} logout={true}/>
            <Main colProps={{className: 'justify-content-between align-items-center d-flex flex-column'}}>
                <Row className="flex-grow-1 align-items-center mt-5">
                    <Col xs={12} md={{span: 10, offset: 1}} className="text-center">
                        <h1 className="mb-5">Revidierte integrierte Diagnose der ADHS im Erwachsenenalter <span className="text-nowrap">– IDA-R</span></h1>

                        <EnterButton
                            onClick={handleStart}
                            tabIndex={10}
                        >
                            Test starten
                        </EnterButton>
                    </Col>
                </Row>

                <ReadMore />
            </Main>
        </Page>
    );
}

export default Home;