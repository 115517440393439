import {useEffect, useState} from "react";

function useKeyPress(targetKey, callback, disabled = false, deps = []) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);

    useEffect(() => {
        // If pressed key is our target key then set to true
        function downHandler({key}) {
            if (!disabled && key === targetKey) {
                setKeyPressed(true);
                callback();
            }
        }

        // If released key is our target key then set to false
        const upHandler = ({key}) => {
            if (!disabled && key === targetKey) {
                setKeyPressed(false);
            }
        };

        // Add event listeners
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [disabled, callback, keyPressed, setKeyPressed, targetKey, ...deps]);

    return keyPressed;
}

export default useKeyPress;