import React, {useState} from "react";
import PropTypes from "prop-types";
import {Accordion, useAccordionToggle} from "react-bootstrap";
import './AdditionalQuestions.sass';
import arrayDownIcon from "../../../../../../images/ico_arrow_down.svg";

const CustomToggle = ({className, children, eventKey}) => {
    const [isActive, setIsActive] = useState(false)

    const handleToggle = useAccordionToggle(eventKey, () => {
        setIsActive(!isActive);
    });

    const activeClass = isActive ? 'active' : ''

    return (
        <Accordion.Toggle
            className={className + ' ' + activeClass}
            onClick={handleToggle}
            tabIndex={5}
        >
            {children}
        </Accordion.Toggle>
    );
}

const AdditionalQuestions = ({additionalQuestions}) => (
    <div className="AdditionalQuestions mt-4 mt-sm-0">
        <Accordion>
            <CustomToggle eventKey="0" className="additional-questions-toggle">
                Optionale Zusatzfragen <img className="img-fluid" src={arrayDownIcon} alt="Pfeil nach unten"/>
            </CustomToggle>

            <Accordion.Collapse eventKey="0">
                <div className="card-body pb-2">
                    <ul className="list-unstyled mb-0">
                        {additionalQuestions.map((additionalQuestion, index) => {
                            return (
                                <li className="additional-question mb-sm-2" key={index}>
                                    {additionalQuestion}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </Accordion.Collapse>

        </Accordion>
    </div>
)

AdditionalQuestions.propTypes = {
    additionalQuestions: PropTypes.array.isRequired,
}

export default AdditionalQuestions;
