import React, {useState}  from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {reset} from "../../../../redux/actions/answers";
import "./HomeButton.sass";
import homeIcon from "../../../../../images/ico_home.svg";
import Debug from "../../../../utility/Debug";
import ConfirmDialog from "../../../ConfirmDialog/ConfirmDialog";
import {ROUTING_HOME, url} from "../../../../utility/Routing";
import PropTypes from "prop-types";

function HomeButton({onConfirmDialogOpen, onConfirmDialogClose}) {
    const dispatch = useDispatch();
    const history = useHistory()
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true);

        if (onConfirmDialogOpen) {
            onConfirmDialogOpen();
        }
    }

    const handleClose = () => {
        setShow(false);

        if (onConfirmDialogClose) {
            onConfirmDialogClose();
        }
    }

    function handleGoToHome() {
        Debug.trace('handleGoToHome');
        dispatch(reset());
        history.push(url(ROUTING_HOME));
    }

    return (
        <>
            <div className="HomeButton ml-2" onClick={handleShow}>
                <img src={homeIcon} className="img-fluid" alt="Home" />
            </div>
            <ConfirmDialog show={show} handleClose={handleClose} handleConfirm={handleGoToHome} />
        </>
    );
}

HomeButton.propTypes = {
    onConfirmDialogOpen: PropTypes.func,
    onConfirmDialogClose: PropTypes.func,
}

export default HomeButton;