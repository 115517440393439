import Debug from "./Debug";
import {ROUTING_CHAPTER, ROUTING_QUESTION, url} from "./Routing";

const questionnaireData = require('./../../questionnaire.json');

let questionnaire = null;

/**
 * Returns questionnaire object with some derived meta data
 *
 * return
 * {
    chaptersCount: int, // the total count of chapters in questionnaire (derived)
    chaptersCountForProgressBar: int, // the total count of chapters for progress bar (chapters that share a label count as 1) (derived)
    questionCount; int, // the total count of questions in the questionnaire (derived)
    stepsCount; int, // the total count of steps (questions, summary, personal data, result) in the questionnaire (derived)
    chapters: [
        {
            id: string, // a unique identifier (i.e. "E.1")
            title: string, // title of chapter
            labelForProgressBar: string, // label for progress bar
            description: ?string, // optional decription of chapter
            questionCount: int, // total count of questions in this chapter (derived)
            hasSummary: boolean, // wether this chapter shows a summary page after all questions are answered (derived: it does, when the chapter has more than 1 question)
            route: string, // path to chapter (derived)
            questions: [
                {
                    question: string, // the question text
                    indexTotal: int, // the index of this question in the whole questionnaire, used for progressbar (derived)
                    choices: ?[
                        choice: string, // the text of this choice (can be derived from defaultChoices)
                        value: int  // the value of this choice (can be derived from defaultChoices)
                        informationOnSelection: ?string // will be shown when this choice is selected
                    ],
                    notes: string, // notes added by the questionnaire giver are stored here
                    selectedChoice: string // the selected choice by the questionnaire taker is stored here,
                    route: string, // path to question (derived)
                }
            ],
            defaultChoices: ?[  // default choices are added to questions when they don't have explicitly defined choices themselves
                {
                    choice: string,
                    value: int
                }
            ],
        }
    ]
}
 */
export function getQuestionnaire() {
    if (questionnaire !== null) {
        return questionnaire;
    }

    questionnaire = questionnaireData;

    questionnaire.chaptersCount = questionnaire.chapters.length;

    let questionIndexTotal = 0;
    let stepsCount = 1; // starting with 1 for Personal Data

    for (let i = 0; i < questionnaire.chapters.length; i++) {

        const chapter = questionnaire.chapters[i];

        chapter.questionsCount = chapter.questions.length;
        chapter.hasSummary = chapter.questions.length > 1;
        chapter.url = url(ROUTING_CHAPTER, {chapterIndex: i})

        if (chapter.hasSummary) {
            stepsCount ++;
        }

        for (let j = 0; j < chapter.questions.length; j++) {
            const question = chapter.questions[j];

            // add an ongoing index to all questions (i.e. for progress bar)
            question.indexTotal = questionIndexTotal++;

            // add default choices to questions without explicitly defined choices
            if (chapter.defaultChoices && !question.choices) {
                question.choices = chapter.defaultChoices;
            }

            // check if any choice has info
            question.hasChoiceInfo = question.choices.filter((choice) => {
                return Boolean(choice.info);
            }).length > 0;

            // add properties to store notes and selected choice
            question.notes = '';
            question.selectedChoice = null;
            question.url = url(ROUTING_QUESTION, {chapterIndex: i, questionIndex: j})

            chapter.questions[j] = question;
        }

        questionnaire.chapters[i] = chapter;

    }

    stepsCount += questionIndexTotal + 1; // +1 for result page

    questionnaire.questionsCount = questionIndexTotal;
    questionnaire.stepsCount = stepsCount;

    Debug.info('questionnaire', questionnaire);

    return questionnaire;
}