import {useRouteMatch} from "react-router-dom";
import {getChapter, getQuestion} from "../../utility/QuestionnaireHelper";
import {getQuestionnaire} from "../../utility/QuestionnaireProvider";
import {path, ROUTING_PERSONAL_DATA, ROUTING_QUESTION, ROUTING_QUESTION_FROM_SUMMARY, ROUTING_RESULT, ROUTING_SUMMARY} from "../../utility/Routing";
import Debug from "../../utility/Debug";
import useChapter from "./useChapter";

const useProgress = () => {
    const match = useRouteMatch();
    const chapter = useChapter();

    return {
        sections: {
            total: getTotalAmountOfSections(),
            labels: getSectionLabels(),
            current: getCurrentSectionIndex(chapter),
        },
        steps: {
            total: getTotalAmountOfSteps(),
            current: getCurrentStep(match),
        }
    }
}

export default useProgress;

function getTotalAmountOfSteps() {
    return getQuestionnaire().stepsCount;
}

function getTotalAmountOfSections() {
    return getQuestionnaire().progress.sections.length;
}

function getSectionLabels() {
    return getQuestionnaire().progress.sections.map((section => section.label));
}

function getCurrentSectionIndex(chapter, match) {
    const sections = getQuestionnaire().progress.sections;

    if (!chapter) {
        return null;
    }

    for (let i = 0; i < sections.length; i++) {
        if (sections[i].chapters.includes(chapter.id)) {
            return i;
        }
    }
}

function getCurrentStep(match) {
    const {chapterIndex, questionIndex} = match.params;

    let step = 0;

    switch (match.path) {
        case path(ROUTING_PERSONAL_DATA):
            step = 1;
            break;

        case path(ROUTING_QUESTION):
        case path(ROUTING_QUESTION_FROM_SUMMARY):
            step = 1 + countSummariesUpToThisChapter(chapterIndex) + (getQuestion(chapterIndex, questionIndex).indexTotal + 1);
            break;

        case path(ROUTING_SUMMARY):
            step = 1 + countSummariesUpToThisChapter(chapterIndex, true) + countQuestionsUpToThisChapter(chapterIndex, true);
            break;

        case path(ROUTING_RESULT):
            step = getTotalAmountOfSteps();
            break;

        default:
            Debug.warn(`getCurrentStepFromRouteMatch: Unexpected path "${match.path}"` )
    }

    Debug.trace('getCurrentStep', step, match);

    return step;
}

function countSummariesUpToThisChapter(chapterIndex, includingThisOne = false) {
    if (includingThisOne) {
        chapterIndex ++;
    }

    let summaries = 0;
    for (let i = 0; i < chapterIndex; i ++) {
        if (getChapter(i).hasSummary) {
            summaries ++;
        }
    }

    return summaries;
}

function countQuestionsUpToThisChapter(chapterIndex, includingThisOne = false) {
    if (includingThisOne) {
        chapterIndex ++;
    }

    let questions = 0;
    for (let i = 0; i < chapterIndex; i ++) {
        questions += getChapter(i).questionsCount;
    }

    return questions;
}