import React from "react";
import {Col, Row} from "react-bootstrap";
import "./ReadMore.sass";

const READ_MORE_URL = 'https://ida-r-anfordern.de';

const ReadMore = () => (
    <Row className="ReadMore mt-3 mb-1 mb-sm-3">
        <hr className="mb-3 mb-sm-4"/>
        <Col xs={12}  md={9} lg={10}>
            <p>Lesen Sie mehr über unser digitales ADHS Diagnose Tool bei einer interaktiven Tour durch den Fragebogen oder klicken Sie durch unsere FAQs. Außerdem können Sie auch ganz einfach unsere IDA-R Mappe anfordern.</p>
        </Col>
        <Col xs={12} md={3} lg={2} className="text-md-right text-center-sm">
            <a href={READ_MORE_URL} target="_blank" rel="noopener noreferrer" className="btn btn-default text-uppercase text-nowrap mb-4 mt-2 mt-md-0">
                Mehr erfahren
            </a>
        </Col>
    </Row>
);

export default ReadMore;
