import React from "react";
import useResult from "../../../../hooks/useResult";
import {PDFViewer} from "@react-pdf/renderer";
import Pdf from "../Pdf/Pdf";

export default function Print() {
    const result = useResult();

    return (
        <PDFViewer style={{width: '100vw', height: '100vh'}}>
            <Pdf result={result} />
        </PDFViewer>
    )
}