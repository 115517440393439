import React  from "react";
import "./ProgressBar.sass";
import ProgressBarSections from "./ProgressBarSections/ProgressBarSections";

const ProgressBar = () => (
    <div className="ProgressBar">
        <ProgressBarSections />
    </div>
)

export default ProgressBar;