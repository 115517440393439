import React from "react";
import PropTypes from "prop-types";
import './Choices.sass';
import Choice from "./Choice/Choice";
import choiceIconNie from './../../../../../../images/ico_choice_nie.svg'
import choiceIconLeicht from './../../../../../../images/ico_choice_leicht.svg'
import choiceIconManchmal from './../../../../../../images/ico_choice_manchmal.svg'
import choiceIconMaessig from './../../../../../../images/ico_choice_maessig.svg'
import choiceIconStark from './../../../../../../images/ico_choice_stark.svg'

const Choices = ({choices, selectedChoice, onChange, keyPressDisabled, tabIndex}) => {
    const variant = 'question-with-' + choices.length + '-choices';

    let icons = [];

    if (choices.length === 4) {
        icons = [
            choiceIconNie,
            choiceIconLeicht,
            choiceIconMaessig,
            choiceIconStark
        ];
    }

    if (choices.length === 5) {
        icons = [
            choiceIconNie,
            choiceIconLeicht,
            choiceIconManchmal,
            choiceIconMaessig,
            choiceIconStark
        ];
    }

    return (
        <div className={'Choices ' + variant}>
            {choices.map((choice, index) => {
                return (
                    <Choice
                        choice={choice}
                        isSelected={index === selectedChoice}
                        onChange={onChange}
                        index={index}
                        key={index}
                        keyPressDisabled={keyPressDisabled}
                        className={'choice-' + (index + 1) + '-of-' + choices.length}
                        tabIndex={tabIndex + index}
                        icon={icons.length > index? icons[index] : null}
                    />
                )
            })}
        </div>
    )
}

Choices.propTypes = {
    selectedChoice: PropTypes.number,
    choices: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    keyPressDisabled: PropTypes.bool,
    tabIndex: PropTypes.number
}

Choices.defaultPropTypes = {
    keyPressDisabled: false,
    tabIndex: 0
}

export default Choices;
