import "./Header.sass";
import React from "react";
import PropTypes from "prop-types";
import BackLink from "./BackLink/BackLink";
import Title from "./Title/Title";
import HomeButton from "./HomeButton/HomeButton";
import ProgressBar from "./ProgressBar/ProgressBar";
import {Col, Row} from "react-bootstrap";
import Logout from "./Logout/Logout";

function Header({backLink, title, logout, homeButton, showProgressBar, keyPressDisabled, onConfirmDialogOpen, onConfirmDialogClose}) {
    return (
        <header className="Header">
            <Row>
                <Col className="d-flex align-items-center justify-content-between mt-3 mb-3">
                    {backLink?
                        <BackLink
                            keyPressDisabled={keyPressDisabled}
                            onConfirmDialogOpen={onConfirmDialogOpen}
                            onConfirmDialogClose={onConfirmDialogClose}
                        /> : <div/>
                    }

                    {title ?
                        <Title title={title}/> : <div/>
                    }

                    <div className="d-flex flex-row justify-content-between">
                        {logout ?
                            <Logout/> : null
                        }
                        {homeButton ?
                            <HomeButton
                                onConfirmDialogOpen={onConfirmDialogOpen}
                                onConfirmDialogClose={onConfirmDialogClose}
                            /> : null
                        }
                    </div>
                </Col>
            </Row>
            {showProgressBar ?
                <Row>
                    <Col>
                        <ProgressBar />
                    </Col>
                </Row>
                : null
            }

        </header>
    );
}

Header.propTypes = {
    title: PropTypes.string,
    backLink: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    logout: PropTypes.bool,
    homeButton: PropTypes.bool,
    keyPressDisabled: PropTypes.bool,
    onConfirmDialogOpen: PropTypes.func,
    onConfirmDialogClose: PropTypes.func,
}

Header.defaultProps = {
    backLink: true,
    logout: false,
    homeButton: true,
    keyPressDisabled: false,
    showProgressBar: false
}

export default Header;