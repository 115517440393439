import {useParams} from "react-router-dom";
import {getChapter} from "../../utility/QuestionnaireHelper";
import Debug from "../../utility/Debug";

const useChapter = () => {
    const {chapterIndex} = useParams();

    if (!chapterIndex) {
        Debug.warn('Missing chapterIndex');
        return;
    }

    const {title, id, questions} = getChapter(chapterIndex);

    return {
        index: chapterIndex,
        id,
        title,
        questions
    }
}

export default useChapter;