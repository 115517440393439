import './Main.sass';
import React from "react";
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";

const Main = ({children, colProps}) => (
    <Row className="Main h-100 flex-grow-1">
        <Col {...colProps}>
            {children}
        </Col>
    </Row>
);

Main.propTypes = {
    colProps: PropTypes.object,
    children: PropTypes.node
}

export default Main;
