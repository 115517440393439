import "./Choice.sass";
import React from "react";
import DOMPurify from 'dompurify';
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import useKeyPress from "../../../../../hooks/useKeyPress";

const Choice = ({choice, isSelected, index, onChange, keyPressDisabled, tabIndex, className, icon}) => {
    const key = String(index + 1);
    useKeyPress(key, handleKeyPress, keyPressDisabled)

    function handleKeyPress() {
        onChange(index);
    }

    return (
        <div className="Choice">
            <Button
                variant="choice"
                onClick={onChange.bind(null, index)}
                className={className + (isSelected? ' active' : '')}
                tabIndex={tabIndex}
            >
                {icon?
                    <div className="choice-icon">
                        <img src={icon} alt={'Icon ' + choice.choice} />
                    </div> : null
                }
                <strong dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(choice.choice)}} />
                <div className="choice-keyboard-binding d-none d-sm-flex">{index + 1}</div>
            </Button>
        </div>
    )
}

Choice.propTypes = {
    choice: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
    keyPressDisabled: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.string
}

Choice.defaultPropTypes = {
    keyPressDisabled: false,
    tabIndex: 0,
    className: ''
}

export default Choice;
