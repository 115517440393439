import React from "react";
import Debug from "../../utility/Debug";
import Page from "../Page/Page";
import Header from "../Page/Header/Header";
import Main from "../Page/Main/Main";

const ErrorPage404 = () => {
    Debug.info('Rendering component %s', 'ErrorPage404');

    return (
        <Page name="NotFound">
            <Header
                title="Fehler 404"
            />
            <Main colProps={{className: 'flex-grow-1 d-flex justify-content-center align-items-center'}}>
                <h1 className="text-center">404 - Seite nicht gefunden :-(</h1>
            </Main>
        </Page>
    )
}

export default ErrorPage404;