import "./BackLink.sass";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Button} from "react-bootstrap";
import {ROUTING_PERSONAL_DATA, path} from "../../../../utility/Routing";
import {reset} from "../../../../redux/actions/answers";
import ConfirmDialog from "../../../ConfirmDialog/ConfirmDialog";
import useKeyPress from "../../../hooks/useKeyPress";
import backIcon from "../../../../../images/ico_back.svg";

function BackLink({keyPressDisabled, onConfirmDialogOpen, onConfirmDialogClose}) {
    const history = useHistory();
    const match = useRouteMatch();
    const dispatch = useDispatch();

    useKeyPress('Backspace', handleKeyPress, keyPressDisabled);

    function handleKeyPress() {
        onClick();
    }

    const [show, setShow] = useState(false);

   const handleShowConfirm = () => {
        setShow(true);

        if (onConfirmDialogOpen) {
            onConfirmDialogOpen();
        }
    }

    const handleCloseConfirm = () => {
        setShow(false);

        if (onConfirmDialogClose) {
            onConfirmDialogClose();
        }
    }

    function onClick() {
        if (match.path === path(ROUTING_PERSONAL_DATA)) {
            handleShowConfirm();
        } else {
            handleBack();
        }
    }

    function handleBack(andReset = false) {
        if (andReset) {
            dispatch(reset())
        }

        history.goBack();
    }

    return (
        <div className="BackLink mr-2">
            <Button variant="unstyled" onClick={onClick}>
                <img src={backIcon} alt="Zurück" />
            </Button>
            <ConfirmDialog show={show} handleClose={handleCloseConfirm} handleConfirm={handleBack.bind(null, true)} />
        </div>
    );
}

BackLink.propTypes = {
    keyPressDisabled: PropTypes.bool,
    onConfirmDialogOpen: PropTypes.func,
    onConfirmDialogClose: PropTypes.func,
}

BackLink.defaultPropTypes = {
    keyPressDisabled: false,
}

export default BackLink;