import debug from 'debug';

export const DEBUG_BASE = 'ida-r-digital-app';

const COLOURS = {
    trace: '#46a9ff',
    info: '#0066ff',
    warn: '#ff8200',
    error: '#ff0000'
};

class Debug {
    generateMessage(level, source, ...message) {
        // Set the prefix which will cause debug to enable the message
        const namespace = `${DEBUG_BASE}:${level}`;
        const createDebug = debug(namespace);

        // Set the colour of the message based on the level
        createDebug.color = COLOURS[level];

        if(source) { createDebug(source, ...message); }
        else { createDebug(...message); }
    }

    trace(source, ...message) {
        return this.generateMessage('trace', source, ...message);
    }

    info(source, ...message) {
        return this.generateMessage('info', source, ...message);
    }

    warn(source, ...message) {
        return this.generateMessage('warn', source, ...message);
    }

    error(source, ...message) {
        return this.generateMessage('error', source, ...message);
    }
}

export default new Debug();