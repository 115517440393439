import {saveAs} from 'file-saver';
import {pdf} from '@react-pdf/renderer';
import Pdf from "./../Pdf/Pdf";
import Debug from "../../../../../utility/Debug";

const generateAndDownloadPdf = async (result, fileName) => {
    Debug.trace(result, fileName);

    const blob = await pdf((
        <Pdf result={result}/>
    )).toBlob();
    saveAs(blob, fileName);
};

export default generateAndDownloadPdf;