import Debug from "./Debug";

const BASE_PATH = '';
const PATH_SEGMENT_LOGIN = 'login';
const PATH_SEGMENT_LOGIN_DOCCHECK = 'doccheck';
const PATH_SEGMENT_LOGIN_MEDICE = 'medice';
const PATH_SEGMENT_LOGOUT = 'logout';
const PATH_SEGMENT_QUESTIONNAIRE = 'questionnaire';
const PATH_SEGMENT_PERSONAL_DATA = 'personal-data';
const PATH_SEGMENT_CHAPTER = 'chapter';
const PATH_SEGMENT_CHAPTER_INDEX = ':chapterIndex';
const PATH_SEGMENT_QUESTION = 'question';
const PATH_SEGMENT_QUESTION_INDEX = ':questionIndex';
const PATH_SEGMENT_SUMMARY = 'summary';
const PATH_SEGMENT_RESULT = 'result';
const PATH_SEGMENT_RESULT_PAGE_PRINT = 'print';

export const ROUTING_HOME = 'home';
export const ROUTING_LOGIN = 'login';
export const ROUTING_LOGIN_DOCCHECK = 'loginDocCheck';
export const ROUTING_LOGIN_MEDICE = 'loginMedice';
export const ROUTING_LOGOUT = 'logout';
export const ROUTING_QUESTIONNAIRE = 'questionnaire';
export const ROUTING_PERSONAL_DATA = 'personalData';
export const ROUTING_CHAPTER = 'chapter';
export const ROUTING_QUESTION = 'question';
export const ROUTING_QUESTION_FROM_SUMMARY = 'questionFromSummary';
export const ROUTING_SUMMARY = 'summary';
export const ROUTING_RESULT = 'result';
export const ROUTING_RESULT_PAGE_PRINT = 'routingResultPagePrint';

/**
 * Returns the url of the given pathname with params.
 *
 * @param pathName
 * @param params
 * @returns {string|null}
 */
export function url(pathName, params = {}) {
    switch (pathName) {
        case ROUTING_HOME:
            return buildPath();

        case ROUTING_LOGIN:
            return buildPath(PATH_SEGMENT_LOGIN);

        case ROUTING_LOGIN_DOCCHECK:
            return buildPath(PATH_SEGMENT_LOGIN, PATH_SEGMENT_LOGIN_DOCCHECK);

        case ROUTING_LOGIN_MEDICE:
            return buildPath(PATH_SEGMENT_LOGIN, PATH_SEGMENT_LOGIN_MEDICE);

        case ROUTING_LOGOUT:
            return buildPath(PATH_SEGMENT_LOGOUT);

        case ROUTING_QUESTIONNAIRE:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE);

        case ROUTING_PERSONAL_DATA:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_PERSONAL_DATA);

        case ROUTING_CHAPTER:
            if (typeof params.chapterIndex === 'undefined' || params.chapterIndex === null) {
                Debug.error('Missing param: chapterIndex', pathName, params);
                return null;
            }

            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_CHAPTER, params.chapterIndex);

        case ROUTING_QUESTION:
            if (typeof params.chapterIndex === 'undefined' || params.chapterIndex === null) {
                Debug.error('Missing param: chapterIndex', pathName, params);
                return null;
            }
            if (typeof params.questionIndex === 'undefined' || params.questionIndex === null) {
                Debug.error('Missing param: questionIndex', pathName, params);
                return null;
            }

            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_CHAPTER, params.chapterIndex, PATH_SEGMENT_QUESTION, params.questionIndex);

        case ROUTING_QUESTION_FROM_SUMMARY:
            if (typeof params.chapterIndex === 'undefined' || params.chapterIndex === null) {
                Debug.error('Missing param: chapterIndex', pathName, params);
                return null;
            }
            if (typeof params.questionIndex === 'undefined' || params.questionIndex === null) {
                Debug.error('Missing param: questionIndex', pathName, params);
                return null;
            }

            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_CHAPTER, params.chapterIndex, PATH_SEGMENT_SUMMARY, PATH_SEGMENT_QUESTION, params.questionIndex);

        case ROUTING_SUMMARY:
            if (typeof params.chapterIndex === 'undefined' || params.chapterIndex === null) {
                Debug.error('Missing param: chapterIndex', pathName, params);
                return null;
            }

            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_CHAPTER, params.chapterIndex, PATH_SEGMENT_SUMMARY);

        case ROUTING_RESULT:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_RESULT);

        case ROUTING_RESULT_PAGE_PRINT:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_RESULT, PATH_SEGMENT_RESULT_PAGE_PRINT);

        default:
            throw new Error('Unknown pathname: ' + pathName);
    }
}

/**
 * Returns the path of the given pathname with placeholders for params.
 *
 * @param pathName
 * @returns {string}
 */
export function path(pathName) {
    switch (pathName) {
        case ROUTING_HOME:
            return buildPath();

        case ROUTING_LOGIN:
            return buildPath(PATH_SEGMENT_LOGIN);

        case ROUTING_LOGIN_DOCCHECK:
            return buildPath(PATH_SEGMENT_LOGIN, PATH_SEGMENT_LOGIN_DOCCHECK);

        case ROUTING_LOGIN_MEDICE:
            return buildPath(PATH_SEGMENT_LOGIN, PATH_SEGMENT_LOGIN_MEDICE);

        case ROUTING_LOGOUT:
            return buildPath(PATH_SEGMENT_LOGOUT);

        case ROUTING_QUESTIONNAIRE:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE);

        case ROUTING_PERSONAL_DATA:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_PERSONAL_DATA);

        case ROUTING_CHAPTER:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_CHAPTER, PATH_SEGMENT_CHAPTER_INDEX);

        case ROUTING_QUESTION:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_CHAPTER, PATH_SEGMENT_CHAPTER_INDEX, PATH_SEGMENT_QUESTION, PATH_SEGMENT_QUESTION_INDEX);

        case ROUTING_QUESTION_FROM_SUMMARY:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_CHAPTER, PATH_SEGMENT_CHAPTER_INDEX, PATH_SEGMENT_SUMMARY, PATH_SEGMENT_QUESTION, PATH_SEGMENT_QUESTION_INDEX);

        case ROUTING_SUMMARY:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_CHAPTER, PATH_SEGMENT_CHAPTER_INDEX, PATH_SEGMENT_SUMMARY);

        case ROUTING_RESULT:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_RESULT);

        case ROUTING_RESULT_PAGE_PRINT:
            return buildPath(PATH_SEGMENT_QUESTIONNAIRE, PATH_SEGMENT_RESULT, PATH_SEGMENT_RESULT_PAGE_PRINT);

        default:
            throw new Error('Unknown pathname: ' + pathName);
    }
}

function buildPath(...segments) {
    let path = BASE_PATH;
    for (let i = 0; i < segments.length; i++) {
        path += '/' + segments[i];
    }

    if (!path) {
        path = '/';
    }

    return path;
}
