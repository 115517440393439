import "./Login.sass";
import React from "react";
import {Route, Switch, useHistory, NavLink, Redirect} from "react-router-dom";
import {Row, Col, Nav} from "react-bootstrap";
import Main from "../Page/Main/Main";
import Page from "../Page/Page";
import Debug from "../../utility/Debug";
import {url, ROUTING_HOME, ROUTING_LOGIN, path, ROUTING_LOGIN_DOCCHECK, ROUTING_LOGIN_MEDICE} from "../../utility/Routing";
import useLogin from "../hooks/useLogin";
import ReadMore from "../Page/ReadMore/ReadMore";
import DocCheckLogin from "./DocCheckLogin/DocCheckLogin";
import MediceLogin from "./MediceLogin/MediceLogin";
import logoDocCheck from "../../../images/logo_doccheck_eumel.png";
import logoMedice from "../../../images/logo_medice_white.svg";

const Login = () => {
    Debug.info('Rendering component %s', 'Login');

    const history = useHistory();

    const {isLoggedIn, login} = useLogin();

    if (isLoggedIn) {
        redirectToHome();
    }

    function handleLogin() {
        Debug.trace('handleLogin');

        login();
        redirectToHome();
    }

    function redirectToHome() {
        history.push(url(ROUTING_HOME));
    }

    return (
        <>
            <Page name="Login">
                <Main colProps={{className: 'align-items-center d-flex flex-column '}}>
                    <Row className="flex-grow-1 w-100 mt-5 mb-5">
                        <Col xs={12} md={{span: 10, offset: 1}}>
                            <h1 className="mb-5">
                                Revidierte integrierte Diagnose der ADHS im Erwachsenenalter <span className="text-nowrap">– IDA-R</span>
                            </h1>

                            <h2 className="text-center">
                                Login für medizinische Fachkreise
                            </h2>

                            <Row>
                                <Col xs={12} md={{span: 10, offset: 1}} className="pl-0 pr-0">
                                    <Nav className="mb-4">
                                        <Nav.Item>
                                            <NavLink className="nav-link" activeClassName="active" to={url(ROUTING_LOGIN_MEDICE)}>
                                                <img className="img-fluid mr-2" alt="Medice Logo" src={logoMedice}/>
                                                <span>Medice Login</span>
                                            </NavLink>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <NavLink className="nav-link" activeClassName="active" to={url(ROUTING_LOGIN_DOCCHECK)}>
                                                <img className="img-fluid mr-2" alt="DocCheck Logo" src={logoDocCheck}/>
                                                <span>DocCheck Login</span>
                                            </NavLink>
                                        </Nav.Item>
                                    </Nav>

                                    <Switch>
                                        <Redirect exact from={path(ROUTING_LOGIN)} to={path(ROUTING_LOGIN_MEDICE)}/>

                                        <Route path={path(ROUTING_LOGIN_MEDICE)}>
                                            <MediceLogin onLoginSuccess={handleLogin}/>
                                        </Route>

                                        <Route path={path(ROUTING_LOGIN_DOCCHECK)}>
                                            <DocCheckLogin onLoginSuccess={handleLogin}/>
                                        </Route>

                                    </Switch>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <ReadMore/>
                </Main>
            </Page>
        </>
    )
}

export default Login;
