import React from "react";
import PropTypes from "prop-types";
import './NextButton.sass';
import checkIcon from "../../../../images/ico_check.svg";
import enterIcon from "../../../../images/ico_enter.svg";
import EnterButton from "../../EnterButton/EnterButton";

const NextButton = ({onClick, show, keyPressDisabled, tabIndex}) => (
    <div className={'NextButton' + (show ? ' show' : '')}>
        <EnterButton
            onClick={onClick}
            disabled={!show}
            tabIndex={tabIndex}
            keyPressDisabled={keyPressDisabled}
            className="text-uppercase"
        >
            Weiter <img className="ml-2 img-fluid" src={checkIcon} alt="Check Icon"/>
        </EnterButton>

        <p className="help text-small d-none d-sm-block">
            <img className="img-fluid enter-icon" src={enterIcon} alt="Enter Icon"/> drücken
        </p>
    </div>
)

NextButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    show: PropTypes.bool,
    keyPressDisabled: PropTypes.bool,
    tabIndex: PropTypes.number
}

NextButton.defaultPropTypes = {
    keyPressDisabled: false,
    show: true,
    tabIndex: 0
}

export default NextButton;
