import React from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";

const ConfirmDialog = ({show, handleClose, handleConfirm}) => (
    <Modal className="modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Ihre Antworten werden beim Verlassen des Tests nicht gespeichert und können nicht wiederhergestellt werden.</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sind Sie sicher, dass Sie den aktuellen Testlauf verlassen möchten?</Modal.Body>
        <Modal.Footer>
            <Button variant="default" className="mr-2" onClick={handleClose}>
                Abbrechen
            </Button>
            <Button variant="danger" onClick={handleConfirm}>
                Test verlassen
            </Button>
        </Modal.Footer>
    </Modal>
)

ConfirmDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
}

export default ConfirmDialog;