import React, {useState} from "react";
import {Row, Col} from "react-bootstrap";
import './Question.sass';
import {useDispatch} from "react-redux";
import {setAnswer, setNotes} from "../../../../redux/actions/answers";
import AdditionalQuestions from "./AdditionalQuestions/AdditionalQuestion";
import Choices from "./Choices/Choices";
import Notes from "./../../Notes/Notes";
import QuestionText from "./QuestionText/QuestionText";
import NextButton from "./../../NextButton/NextButton";
import Debug from "../../../../utility/Debug";
import Page from "../../../Page/Page";
import Header from "../../../Page/Header/Header";
import Main from "../../../Page/Main/Main";
import Info from "./Info/Info";
import DownloadASRSPrintVersion from "./DownloadASRSPrintVersion/DownloadASRSPrintVersion";
import useQuestion from "../../../hooks/useQuestion";
import useChapter from "../../../hooks/useChapter";
import {answerIsValid} from "../../../../utility/QuestionnaireHelper";
import useAnswer from "../../../hooks/useAnswer";
import useNextUrl from "../../../hooks/useNextUrl";

function getChoiceInfo(selectedChoice, choices) {
    if (selectedChoice !== null && choices[selectedChoice] && choices[selectedChoice].info) {
        return choices[selectedChoice].info;
    }

    return null;
}

const Question = () => {
    const dispatch = useDispatch();

    const question = useQuestion();
    const chapter = useChapter();
    const answer = useAnswer();
    const {goToNext} = useNextUrl();

    Debug.info('Rendering component %s', 'Question', chapter, question, answer);

    const [notesActive, setNotesActive] = useState(false);
    const [keyPressDisabled, setKeyPressDisabled] = useState(false);

    const showNext = answer.selectedChoice !== null;

    // Choice Info is a text that will be shown when a certain choice is selected. Currently used only for Chapter B Question 1 Choice "Yes".
    const choiceInfo = getChoiceInfo(answer.selectedChoice, question.choices);

    // The first question of the first chapter has a download.
    const showDownloadASRSPrintVersionDownload = chapter.index === '0' && question.index === '0';


    function handleNotesInput(e) {
        Debug.trace('handleNotesInput', e.target.value);
        dispatch(setNotes(chapter.index, question.index, e.target.value));
    }

    function handleChoiceChange(index) {
        Debug.trace('handleChoiceChange', chapter.index, question.index, index);
        dispatch(setAnswer(chapter.index, question.index, index));
    }

    function handleNext() {
        Debug.trace('handleNext');

        if (!answerIsValid(answer.selectedChoice, question)) {
            Debug.error('Invalid answer: %s', chapter.index, question.index, answer)
            return;
        }

        goToNext();
    }

    function handleConfirmDialogOpen() {
        setKeyPressDisabled(true);
    }

    function handleConfirmDialogClose() {
        setKeyPressDisabled(false);
    }

    return (
        <Page name="Question">
            <Header
                title={chapter.title}
                showProgressBar={true}
                keyPressDisabled={notesActive || keyPressDisabled}
                onConfirmDialogOpen={handleConfirmDialogOpen}
                onConfirmDialogClose={handleConfirmDialogClose}
            />

            <Main colProps={{className: 'd-flex flex-column flex-grow-1'}}>
                <QuestionText number={question.number} question={question.question} description={question.description}/>

                {question.additionalQuestions ?
                    <AdditionalQuestions key={chapter.index + '-' + question.index} additionalQuestions={question.additionalQuestions}/>
                    : null
                }

                <hr/>

                <div className="flex-grow-1">
                    <Choices
                        choices={question.choices}
                        onChange={handleChoiceChange}
                        keyPressDisabled={notesActive || keyPressDisabled}
                        selectedChoice={answer.selectedChoice}
                        tabIndex={50}
                    />

                    {question.hasChoiceInfo ?
                        <Info
                            show={Boolean(choiceInfo)}
                            text={choiceInfo}
                        /> : null
                    }

                    {showDownloadASRSPrintVersionDownload ?
                        <DownloadASRSPrintVersion/> : null
                    }

                </div>

                <Row>
                    <Col xs={12} md={9} lg={10}>
                        <Notes
                            notes={answer.notes}
                            active={notesActive}
                            onBlur={setNotesActive.bind(null, false)}
                            onFocus={setNotesActive.bind(null, true)}
                            onChange={handleNotesInput}
                            tabIndex={60}
                        />
                    </Col>

                    <Col xs={12} md={3} lg={2}>
                        <NextButton
                            show={showNext}
                            onClick={handleNext}
                            keyPressDisabled={notesActive || keyPressDisabled}
                            tabIndex={70}
                        />
                    </Col>
                </Row>
            </Main>
        </Page>
    );
}

export default Question;
