import React from "react";
import PropTypes from "prop-types";
import {Page, Text, View, Document, Image} from '@react-pdf/renderer';
import logoAdhs from "../../../../../../images/logo_adhs.png";
import logoMedice from "../../../../../../images/logo_medice.png";
import iconFail from "../../../../../../images/ico_conditions_failed.png";
import iconSuccess from "../../../../../../images/ico_conditions_met.png";
import styles from "./Styles";
import {DIAGNOSE_KEINE_ADHS} from "../../../../hooks/useResult";

function getNotes(notes) {
    return (
        <View style={[styles.colorPurple, styles.offsetLeft, styles.pt1, styles.pb1, styles.borderTopDashed]}>
            <Text>ANMERKUNG: {notes}</Text>
        </View>
    )
}

export default function Pdf({result}) {
    const {diagnosis, additionalDiagnosis, personalData} = result;
    const chapters = result.chapters.slice(1)

    const Header = () => (
        <View fixed style={[styles.header]}>
            <Image src={logoAdhs} style={[styles.logoAdhs, styles.alignSelfEnd]}/>
            <Text style={[styles.h1, styles.colorPurple, styles.fontWeightBold, styles.textCenter]}>
                Revidierte integrierte Diagnose der ADHS{'\n'}
                im Erwachsenenalter – IDA-R
            </Text>
        </View>
    );

    const Footer = () => (
        <View fixed style={[styles.footer, styles.flexRow, styles.justifyContentBetween]}>
            <Text style={[styles.alignSelfCenter, {maxWidth: '45%', fontSize: '10pt'}]}>
                {personalData.date}{'\n'}
                {personalData.name}
            </Text>
            <Text
                style={[styles.alignSelfCenter, styles.justifyContentCenter, styles.pageNumber]}
                fixed
                render={(textProps) => (
                    <Text>
                        - {textProps?.pageNumber?.toString()} -
                    </Text>
                )}
            />
            <View style={[styles.justifyContentEnd]}>
                <Image src={logoMedice} style={[styles.logoMedice]}/>
            </View>
        </View>
    )

    return (
        <Document>
            <Page size="A4" style={[styles.page, styles.flexColumn]} wrap>
                <Header />
                <Footer />

                <View style={styles.chapterSummary}>
                    <View style={[styles.flexRow, styles.justifyContentBetween, styles.mb1]}>
                        <Text style={[styles.h2, styles.colorPurple, styles.fontWeightBold]}>
                            Persönliche Angaben
                        </Text>
                        <Text style={[styles.alignSelfEnd]}>
                            {personalData.date}
                        </Text>
                    </View>

                    <View style={[styles.flexRow, styles.justifyContentBetween]}>
                        <Text style={[styles.chapterSummaryQuestionNumber]}/>

                        <View style={[styles.flexRow, styles.flexGrow, styles.justifyContentBetween, styles.borderBottom, styles.pb1, styles.mb1]}>
                            <Text style={[styles.flexGrow]}>
                                NAME:
                            </Text>
                            <View style={[styles.flexGrow]}>
                                <Text style={[styles.alignSelfEnd, styles.fontWeightBold]}>
                                    {personalData.name}
                                </Text>
                            </View>
                        </View>

                    </View>
                    {personalData.notes ?
                        getNotes(personalData.notes) : null
                    }
                </View>

                {chapters.map((chapter, index) => (
                    <View style={[styles.chapterSummary]} key={index}>
                        <View style={[styles.flexRow, styles.justifyContentBetween]} minPresenceAhead={20}>
                            <Text style={[styles.h2, styles.colorPurple, styles.fontWeightBold]}>
                                {chapter.title}
                            </Text>
                        </View>

                        {chapter.questions.map((question, index) => (
                            <View style={[styles.mt2]} key={index} wrap={false}>
                                <View style={[styles.flexRow, styles.flexGrow, styles.justifyContentBetween]}>
                                    <Text style={[styles.chapterSummaryQuestionNumber, styles.textRight, styles.pr1]}>
                                        {index + 1}.
                                    </Text>

                                    <View style={[styles.flexRow, styles.flexGrow, styles.justifyContentBetween, styles.mb1]}>
                                        <Text style={[styles.flexGrow, styles.alignSelfEnd, {width: '50%'}]}>
                                            {String(question.question).replace('&shy;', '')}
                                        </Text>
                                        <View style={[styles.flexGrow, styles.justifyContentEnd]}>
                                            {question.answer ?
                                                <Text style={[styles.alignSelfEnd, styles.fontWeightBold]}>
                                                    {question.answer}
                                                </Text> : null
                                            }
                                        </View>
                                    </View>
                                </View>

                                {question.notes ?
                                    getNotes(question.notes) : null
                                }

                                <View style={[styles.offsetLeft, styles.borderBottom]}/>
                            </View>
                        ))}

                        <View style={[styles.flexRow, styles.offsetLeft, styles.mt5, styles.mb4]}>
                            {chapter.conditionsMet ?
                                <>
                                    <Image src={iconSuccess} style={[styles.conditionsIcon, styles.alignSelfCenter]}/>
                                    <Text style={[styles.conditionsLabel, styles.colorPurple, styles.alignSelfCenter]}>KRITERIEN SIND ERFÜLLT</Text>
                                </> :
                                <>
                                    <Image src={iconFail} style={[styles.conditionsIcon, styles.alignSelfCenter]}/>
                                    <Text style={[styles.conditionsLabel, styles.colorPurple, styles.alignSelfCenter]}>KRITERIEN SIND NICHT ERFÜLLT</Text>
                                </>
                            }
                        </View>

                    </View>
                ))}
            </Page>

            <Page size="A4" style={[styles.page, styles.flexColumn]} wrap>
                <Header />
                <Footer />

                <View style={[styles.flexGrow]}>
                    <View style={[styles.flexGrow]}>
                        <View style={[styles.flexRow, styles.mb3]}>
                            <Text style={[styles.diagnosis, styles.colorPurple, styles.fontWeightBold, styles.textCenter]}>
                                Diagnose:{'\n'}
                                {diagnosis.label}
                            </Text>
                        </View>

                        <View style={[styles.offsetLeft]}>
                            <Text style={{marginBottom: '2pt'}}>
                                {diagnosis.explanation}
                            </Text>
                            <Text style={{marginBottom: '2pt'}}>
                                {diagnosis.key === DIAGNOSE_KEINE_ADHS ?
                                    'Diagnosekriterien gemäß DMS-IV nicht erfüllt.'
                                    : 'Diagnosekriterien gemäß DMS-IV erfüllt.'
                                }
                            </Text>
                        </View>

                        {additionalDiagnosis.length > 0 ?
                            <View style={[styles.colorPurple, styles.offsetLeft, styles.borderTopDashed, {
                                paddingTop: '9pt'
                            }]}>
                                {additionalDiagnosis.map((diagnosis, index) => (
                                    <Text key={index} style={[styles.additionalDiagnosis, styles.colorPurple, styles.pb1]}>{diagnosis.label}</Text>
                                ))}
                            </View> : null
                        }

                        {diagnosis.notes ?
                            getNotes(diagnosis.notes) : null
                        }

                        <View style={[styles.offsetLeft, styles.borderBottom]}/>
                    </View>

                    <View style={[styles.flexRow, styles.justifyContentBetween]}>
                        <View style={[styles.flexGrow, styles.arztStempel, styles.justifyContentEnd, styles.mr2]}>
                            <Text style={[styles.pb1, {
                                paddingLeft: '8pt',
                                fontSize: '8pt'
                            }]}>Arztstempel</Text>
                        </View>
                        <View  style={[styles.flexGrow, styles.justifyContentEnd, styles.ml2]}>
                            <Text style={[styles.borderTop, styles.pb1, {
                                paddingTop: '3pt',
                                fontSize: '8pt'
                            }]}>Unterschrift</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

Pdf.propTypes = {
    result: PropTypes.object.isRequired
}