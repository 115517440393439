const questionnaireData = require('./../../questionnaire.json');

export function getInitialAnswersState() {
    const initialAnswerState = {
        notes: '',
        personalData: {
            name: '',
            date: null
        },
        chapters: [],
    };

    for (let i = 0; i < questionnaireData.chapters.length; i++) {
        const chapter = questionnaireData.chapters[i];
        initialAnswerState.chapters.push(
            {
                questions: []
            }
        );
        for (let j = 0; j < chapter.questions.length; j++) {
            initialAnswerState.chapters[i].questions.push(
                {
                    answer: null,
                    notes: ''
                }
            )
        }
    }

    return initialAnswerState;
}