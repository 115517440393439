import {useParams} from "react-router-dom";
import {getQuestion} from "../../utility/QuestionnaireHelper";
import Debug from "../../utility/Debug";

const useQuestion = () => {
    const {questionIndex, chapterIndex} = useParams();

    if (!chapterIndex || !questionIndex) {
        Debug.warn('Missing chapterIndex or questionIndex');
        return;
    }

    const {question, number, description, choices, additionalQuestions, indexTotal, hasChoiceInfo} = getQuestion(chapterIndex, questionIndex);

    return {
        index: questionIndex,
        indexTotal,
        question,
        number,
        description,
        choices,
        additionalQuestions,
        hasChoiceInfo,
    }
}

export default useQuestion;