import './Page.sass';
import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer/Footer";

const Page = ({name, children}) => (
    <div className={name + ' h-100 flex-grow-1 d-flex flex-column'}>
        {children}
        <Footer/>
    </div>
);

Page.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node
}


export default Page;
