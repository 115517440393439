export const SET_ANSWER = 'SET_ANSWER';
export const SET_NOTES = 'SET_NOTES';
export const SET_PERSONAL_DATA = 'SET_PERSONAL_DATA';
export const SET_RESULT_NOTES = 'SET_RESULT_NOTES';
export const RESET = 'RESET';

export const setAnswer = (chapterIndex, questionIndex, answer) => ({
    type: SET_ANSWER,
    payload: {
        chapterIndex,
        questionIndex,
        answer
    }
})

export const setNotes = (chapterIndex, questionIndex, notes) => ({
    type: SET_NOTES,
    payload: {
        chapterIndex,
        questionIndex,
        notes
    }
})

export const setPersonalData = (name, date) => ({
    type: SET_PERSONAL_DATA,
    payload: {
        name: name,
        date: date
    }
})

export const setResultNotes = (notes) => ({
    type: SET_RESULT_NOTES,
    payload: {
        notes
    }
})

export const reset = () => {
    localStorage.clear();

    return {
        type: RESET
    };
}