import React from "react";
import PropTypes from "prop-types";
import DOMPurify from 'dompurify';
import './QuestionText.sass';

const QuestionText = ({number, question, description}) => (
    <div className="QuestionText">
        <div className="question">
            {number?
                <span className="number mr-2">
                    {number}
                </span> : null
            }
            <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question)}} />
        </div>
        {description ?
            <p className="question-description">{description}</p>
            : null
        }
    </div>
)

QuestionText.propTypes = {
    number: PropTypes.string,
    question: PropTypes.string.isRequired,
    description: PropTypes.string
}

export default QuestionText;
