import {useSelector} from "react-redux";
import Debug from "../../utility/Debug";
import {areConditionsMet, getChapterById, getChapterIndexFromId} from "../../utility/QuestionnaireHelper";
import {getFormattedDate} from "../../utility/Date";

export const DIAGNOSE_KOMBINIERTE_ADHS = 'diagnose_kombinierte_adhs';
export const DIAGNOSE_ADHS_UNAUFMERKSAMER_TYP = 'diagnose_adhs_unaufmerksamer_typ';
export const DIAGNOSE_ADHS_HYPERAKTIV_IMPULSIVER_TYP = 'diagnose_adhs_hyperaktiv_impulsiver_typ';
export const DIAGNOSE_KEINE_ADHS = 'diagnose_keine_adhs';
export const VERDACHT_EMOTIONALE_DYSREGULATION = 'verdacht_emotionale_dysregulation';
export const VERDACHT_SOZIALVERHALTENSSTOERUNG = 'verdacht_stoerungen_des_sozialverhaltens';

const useResult = () => {
    const answers = useSelector(state => state.answers);

    const diagnosis = getDiagnosis(answers);
    const additionalDiagnosis = getAdditionalDiagnosis(answers);

    return {
        diagnosis: {
            key: diagnosis,
            label: getTranslatedDiagnosis(diagnosis),
            explanation: getExplanation(diagnosis),
            notes: answers.notes
        },
        additionalDiagnosis: additionalDiagnosis.map(additionalDiagnosis => (
            {
                key: additionalDiagnosis,
                label: getTranslatedAdditionalDiagnosis(additionalDiagnosis)
            }
        )),
        personalData: {
            date: getFormattedDate(new Date(answers.personalData.date)),
            name: answers.personalData.name,
            notes: answers.personalData.notes
        },
        chapters: getChapterResults(answers),
    }
}

export default useResult;

function getChapterResults(answers) {
    const chapterResults = [];

    let nextChapterId = 'A.2.intro'

    while (String(nextChapterId).toLowerCase() !== 'result') {
        const chapter = getChapterById(nextChapterId)
        const chapterResult = getChapterResult(chapter, answers);
        chapterResults.push(chapterResult);
        nextChapterId = chapterResult.nextChapterId;
    }

    return chapterResults;
}

function getChapterResult(chapter, answers) {
    const chapterIndex = getChapterIndexFromId(chapter.id);

    const conditionsMet = areConditionsMet(chapterIndex, answers);
    let nextChapterId = conditionsMet ? chapter.next.conditionsMet : chapter.next.conditionsNotMet;

    if (chapter.next.continueWhenConditionsAreMetBy) {
        if (areConditionsMet(getChapterIndexFromId(chapter.next.continueWhenConditionsAreMetBy), answers)) {
            nextChapterId = chapter.next.conditionsMet;
        }
    }

    return {
        title: chapter.title,
        conditionsMet,
        nextChapterId,
        questions: chapter.questions.map((question, questionIndex) => {
            return {
                number: question.number? question.number : '',
                question: question.question,
                answer: question.choices[getAnswerForQuestion(answers, chapterIndex, questionIndex)] ?
                    question.choices[getAnswerForQuestion(answers, chapterIndex, questionIndex)].choice : null,
                notes: getNotesForQuestion(answers, chapterIndex, questionIndex)
            }
        })
    }
}

function getDiagnosis(answers) {
    const chapterIndexB = getChapterIndexFromId('B');
    const chapterIndexC = getChapterIndexFromId('C');
    const chapterIndexE1 = getChapterIndexFromId('E.1');
    const chapterIndexE2 = getChapterIndexFromId('E.2');
    const chapterIndexE3_6 = getChapterIndexFromId('E.3-6');

    if (!(areConditionsMet(chapterIndexB, answers) || areConditionsMet(chapterIndexC, answers)) || !areConditionsMet(chapterIndexE3_6, answers)) {
        return DIAGNOSE_KEINE_ADHS;
    }

    const areConditionsMetForE1 = areConditionsMet(chapterIndexE1, answers);
    const areConditionsMetForE2 = areConditionsMet(chapterIndexE2, answers);


    if (areConditionsMetForE1 && areConditionsMetForE2) {
        return DIAGNOSE_KOMBINIERTE_ADHS;
    }

    if (areConditionsMetForE1 && !areConditionsMetForE2) {
        return DIAGNOSE_ADHS_UNAUFMERKSAMER_TYP;
    }

    if (!areConditionsMetForE1 && areConditionsMetForE2) {
        return DIAGNOSE_ADHS_HYPERAKTIV_IMPULSIVER_TYP;
    }

    return DIAGNOSE_KEINE_ADHS;
}

function getAdditionalDiagnosis(answers) {
    const additionalDiagnosis = [];
    const chapterIndexD = getChapterIndexFromId('D');

    if (Boolean(answers.chapters[chapterIndexD].questions[0].answer)) {
        additionalDiagnosis.push(VERDACHT_EMOTIONALE_DYSREGULATION)
    }

    if (Boolean(answers.chapters[chapterIndexD].questions[1].answer) || Boolean(answers.chapters[chapterIndexD].questions[2].answer)) {
        additionalDiagnosis.push(VERDACHT_SOZIALVERHALTENSSTOERUNG)
    }

    return additionalDiagnosis;
}

function getTranslatedDiagnosis(diagnosis) {
    switch (diagnosis) {
        case (DIAGNOSE_KOMBINIERTE_ADHS):
            return 'Kombinierte ADHS';
        case (DIAGNOSE_ADHS_UNAUFMERKSAMER_TYP):
            return 'ADHS - unaufmerksamer Typ';
        case (DIAGNOSE_ADHS_HYPERAKTIV_IMPULSIVER_TYP):
            return 'ADHS - hyperaktiv-impulsiver Typ';
        default:
            return 'Keine ADHS';
    }
}

function getTranslatedAdditionalDiagnosis(diagnosis) {
    switch (diagnosis) {
        case (VERDACHT_EMOTIONALE_DYSREGULATION):
            return 'Verdacht auf ADHS mit emotionaler Dysregulation';
        case (VERDACHT_SOZIALVERHALTENSSTOERUNG):
            return 'Verdacht auf ADHS mit Störung des Sozialverhaltens';
        default:
            Debug.error(`Unexpected diagnosis: ${diagnosis}`);
    }
}

function getExplanation(diagnosis) {
    switch (diagnosis) {
        case (DIAGNOSE_KOMBINIERTE_ADHS):
            return 'B oder C sowie E.1-6 erfüllt.';
        case (DIAGNOSE_ADHS_UNAUFMERKSAMER_TYP):
            return 'B oder C sowie E.1 erfüllt.';
        case (DIAGNOSE_ADHS_HYPERAKTIV_IMPULSIVER_TYP):
            return 'B oder C sowie E.2 erfüllt.';
        default:
            return 'Symptomkriterien nicht erfüllt.';
    }
}

function getAnswerForQuestion(answers, chapterIndex, questionIndex) {
    return answers.chapters[chapterIndex].questions[questionIndex].answer;
}

function getNotesForQuestion(answers, chapterIndex, questionIndex) {
    return answers.chapters[chapterIndex].questions[questionIndex].notes;
}