import React, {useState} from "react";
import {Form, Row, Col} from "react-bootstrap";
import Debug from "../../../utility/Debug";
import {useDispatch, useSelector} from "react-redux";
import NextButton from "../NextButton/NextButton";
import {setPersonalData} from "../../../redux/actions/answers";
import "./PersonalData.sass";
import Header from "../../Page/Header/Header";
import Main from "../../Page/Main/Main";
import Page from "../../Page/Page";
import useNextUrl from "../../hooks/useNextUrl";

const PersonalData = () => {
    Debug.info('Rendering component %s', 'PersonalData');

    const dispatch = useDispatch();
    const {goToNext} = useNextUrl();

    const Answers = useSelector(state => state.answers);
    const [name, setName] = useState(Answers.personalData.name);
    const [keyPressDisabled, setKeyPressDisabled] = useState(false);
    const [inputActive, setInputActive] = useState(false);

    function handleConfirmDialogOpen() {
        setKeyPressDisabled(true);
    }

    function handleConfirmDialogClose() {
        setKeyPressDisabled(false);
    }

    function formIsValid() {
        return name && name.length > 0;
    }

    function handleFocus(e) {
        e.currentTarget.select();
        setInputActive(true);
    }

    function handleBlur() {
        setInputActive(false);
    }

    function handleChange(e) {
        setName(e.target.value)
    }

    function handleNext() {
        if (!formIsValid()) {
            return;
        }

        dispatch(setPersonalData(name, new Date()));

        goToNext();
    }

    return (
        <>
            <Page name="PersonalData">
                <Header
                    title="Persönliche Daten"
                    showProgressBar={true}
                    keyPressDisabled={inputActive || keyPressDisabled}
                    onConfirmDialogOpen={handleConfirmDialogOpen}
                    onConfirmDialogClose={handleConfirmDialogClose}
                />

                <Main>
                    <Row>
                        <Col>
                            <h1 className="mt-5 mt-sm-0">Persönliche Daten</h1>
                            <hr/>
                        </Col>
                    </Row>
                    <Row className="flex-grow-1">
                        <Col xs={12} md={{span: 10, offset: 1}} lg={{span: 8, offset: 2}}>
                            <Form.Group controlId="name" className="mt-5">
                                <Form.Label>Name des Patienten</Form.Label>
                                <Form.Control
                                    value={name}
                                    type="text"
                                    placeholder="Erika Musterfrau"
                                    onChange={handleChange}
                                    disabled={keyPressDisabled}
                                    autoFocus
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                />
                            </Form.Group>

                            <p className="help mt-4 mb-4 mt-sm-0">
                                <small>Dieser Fragebogen bezieht sich auf die Diagnose der ADHS im <strong>Erwachsenenalter</strong>. <br/>
                                    <strong>Ihr Patient sollte mindestens 18 Jahre alt sein.</strong>
                                </small>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={{span: 3, offset: 9}} lg={{span: 2, offset: 10}}>
                            <NextButton
                                show={Boolean(formIsValid())}
                                onClick={handleNext}
                                keyPressDisabled={keyPressDisabled}
                            />
                        </Col>
                    </Row>
                </Main>
            </Page>
        </>
    )
}

export default PersonalData;