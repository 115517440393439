import {useCookies} from 'react-cookie';
import {path, ROUTING_LOGIN} from "../../utility/Routing";
import Debug from "../../utility/Debug";

const COOKIE_NAME = 'login';

const COOKIE_MAX_AGE = 60 * 60 * 24 * 30 * 12;

const openPaths = [
    path(ROUTING_LOGIN)
]

const useLogin = () => {
    const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME]);

    const login = () => {
        Debug.trace('Login')

        setCookie(COOKIE_NAME, true, {maxAge: COOKIE_MAX_AGE, path: '/'});
    }

    const logout = () => {
        Debug.trace('Logout')

        removeCookie(COOKIE_NAME, {path: '/'});
    }

    return {
        isLoggedIn: Boolean(cookies[COOKIE_NAME]),
        isSecuredPath: !openPaths.includes(window.location.pathname),
        login,
        logout
    }
}

export default useLogin;