import {useSelector} from "react-redux";
import useChapter from "./useChapter";
import {areConditionsMet} from "../../utility/QuestionnaireHelper";

const useSummary = () => {
    const Answers = useSelector(state => state.answers);

    let chapter = useChapter();

    const answersForChapter = Answers.chapters[chapter.index]

    const conditionsMet = areConditionsMet(chapter.index, Answers);

    return {
        chapter,
        answers: answersForChapter,
        conditionsMet
    }
}

export default useSummary;
