import {useHistory, useRouteMatch} from "react-router-dom";
import {path, ROUTING_CHAPTER, ROUTING_PERSONAL_DATA, ROUTING_QUESTION, ROUTING_QUESTION_FROM_SUMMARY, ROUTING_RESULT, ROUTING_SUMMARY, url} from "../../utility/Routing";
import {useSelector} from "react-redux";
import Debug from "../../utility/Debug";
import {areConditionsMet, getChapter, getChapterIndexFromId, isLastQuestionInChapter} from "../../utility/QuestionnaireHelper";

// Determines next url in questionnaire based on current state
const useNextUrl = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const Answers = useSelector(state => state.answers);

    const nextUrl = getNextUrlFromRouteMatch(match, Answers);

    return {
        nextUrl,
        goToNext: () => history.push(nextUrl)
    };
}

export default useNextUrl;

function getNextUrlFromRouteMatch(match, Answers) {
    Debug.trace('getNextRouteFromRouteMatch', match);

    const {chapterIndex, questionIndex} = match.params;
    const chapter = chapterIndex ? getChapter(chapterIndex) : null;

    switch (match.path) {
        case path(ROUTING_PERSONAL_DATA):
            return url(ROUTING_CHAPTER, {chapterIndex: 0})

        case path(ROUTING_QUESTION):
            if (!isLastQuestionInChapter(chapterIndex, questionIndex)) {
                return url(ROUTING_QUESTION, {chapterIndex: chapterIndex, questionIndex: parseInt(questionIndex) + 1})
            }

            if (chapter.hasSummary) {
                return url(ROUTING_SUMMARY, {chapterIndex});
            }

            return getUrlToNextChapterOrResult(chapterIndex, Answers);

        case path(ROUTING_QUESTION_FROM_SUMMARY):
            if (!getChapter(chapterIndex).hasSummary) {
                throw new Error('Chapter has no summary.');
            }
            return url(ROUTING_SUMMARY, {chapterIndex});

        case path(ROUTING_SUMMARY):
            return getUrlToNextChapterOrResult(chapterIndex, Answers);

        default:
            throw new Error('Next Route not found');
    }
}

function getUrlToNextChapterOrResult(chapterIndex, Answers) {
    const next = getNextChapterIndexOrResult(chapterIndex, Answers);

    if (String(next).toLowerCase() === 'result') {
        return url(ROUTING_RESULT);
    }

    return url(ROUTING_CHAPTER, {chapterIndex: getChapterIndexFromId(next)});
}

function getNextChapterIndexOrResult(chapterIndex, Answers) {
    const chapter = getChapter(chapterIndex);
    const conditionsMet = areConditionsMet(chapterIndex, Answers);

    let next = conditionsMet ? chapter.next.conditionsMet : chapter.next.conditionsNotMet;

    if (!conditionsMet && chapter.next.continueWhenConditionsAreMetBy) {
        const conditionsMetByOtherChapter = areConditionsMet(getChapterIndexFromId(chapter.next.continueWhenConditionsAreMetBy), Answers);
        if (conditionsMetByOtherChapter) {
            next = chapter.next.conditionsMet;
        }
    }

    return next;
}