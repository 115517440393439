import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import useKeyPress from "../hooks/useKeyPress";

const EnterButton = ({onClick, disabled, keyPressDisabled, tabIndex, children, ...props}) => {
    useKeyPress('Enter', handleKeyPress, disabled || keyPressDisabled);

    let className = 'EnterButton d-inline-flex align-items-center justify-content-center mb-1';
    if (props.className) {
        className += ' ' + props.className;
    }

    function handleKeyPress() {
        onClick();
    }

    return (
        <Button onClick={onClick} className={className} disabled={disabled} tabIndex={tabIndex}>
            {children}
        </Button>
    )
}

EnterButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    keyPressDisabled: PropTypes.bool,
    tabIndex: PropTypes.number
}

EnterButton.defaultPropTypes = {
    keyPressDisabled: false,
    disabled: false,
    tabIndex: 0
}

export default EnterButton;
