import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import store from './scripts/redux/store'
import {BrowserRouter as Router} from 'react-router-dom';
import './styles/index.sass';
import App from './scripts/react-components/App';
import reportWebVitals from './scripts/reportWebVitals';
import Debug, {DEBUG_BASE} from "./scripts/utility/Debug";
import {CookiesProvider} from "react-cookie";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Debug.info(this, 'process.env.NODE_ENV', process.env.NODE_ENV);

if (process.env.NODE_ENV !== 'production') {
    localStorage.setItem('debug', DEBUG_BASE + ':*');
}

const storeInstance = store(
    JSON.parse(localStorage.getItem('ida-r-digital-app-state')) || {}
);

storeInstance.subscribe(() => localStorage.setItem(
    'ida-r-digital-app-state',
    JSON.stringify(storeInstance.getState())
));

ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider>
            <Provider store={storeInstance}>
                <Router>
                    <App />
                </Router>
            </Provider>
        </CookiesProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.NODE_ENV === 'production') {
    serviceWorkerRegistration.register();
}
