import {Alert, Col, Fade, Row} from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";
import './Info.sass';

const Info = ({show, text}) => (
    <Fade in={show} appear={true} className="Info">
        <Row>
            <Col xs={12} lg={{span: 8, offset: 2}}>
                <Alert variant="info">
                    {text}
                </Alert>
            </Col>
        </Row>
    </Fade>
)

Info.propTypes = {
    text: PropTypes.string,
    show: PropTypes.bool
}

Info.defaultProps = {
    show: false
}

export default Info;