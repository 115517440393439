import React from "react";
import {Button} from 'react-bootstrap';
import Debug from "../../../../utility/Debug";
import {useHistory} from "react-router-dom";
import {ROUTING_LOGIN, url} from "../../../../utility/Routing";
import useLogin from "../../../hooks/useLogin";

const Logout = () => {
    Debug.info('Rendering component %s', 'Logout');

    const {isLoggedIn, logout} = useLogin();
    const history = useHistory();

    function handleLogout() {
       Debug.trace('logout');
       logout();
       redirectToLogin();
    }

    function redirectToLogin() {
        history.push(url(ROUTING_LOGIN));
    }

    if (!isLoggedIn) {
        return null;
    }

    return (
        <>
            <Button variant="link" onClick={handleLogout} className="text-uppercase">Logout</Button>
        </>
    )
}

export default Logout;
