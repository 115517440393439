import React from "react";
import {Button} from "react-bootstrap";
import {saveAs} from 'file-saver';
import downloadIcon from "../../../../../../images/ico_download_white.svg";
import pdf from "../../../../../../downloads/Screeningtest-ASRS.pdf";
import "./DownloadASRSPrintVersion.sass";

const FILE_NAME = 'asrs-print.pdf'

const DownloadASRSPrintVersion = () => {
    function handleDownload() {
        saveAs(pdf, FILE_NAME)
    }

    return (
        <div className="DownloadASRSPrintVersion">
            <div className="line-with-text">
                oder
            </div>
            <div className="text-center">
                <Button variant="default" className="text-uppercase" onClick={handleDownload}>
                    <span>Druckversion herunterladen</span> <img className="ml-1 img-fluid" src={downloadIcon} alt="Download"/>
                </Button>
            </div>
        </div>
    )
}

export default DownloadASRSPrintVersion;