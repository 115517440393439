import React from "react";
import {Redirect, Switch, Route, useRouteMatch} from 'react-router-dom';
import {path, url, ROUTING_CHAPTER, ROUTING_PERSONAL_DATA, ROUTING_RESULT} from "../../utility/Routing";
import Chapter from "./Chapter/Chapter";
import Result from "./Result/Result";
import ErrorPage404 from "../ErrorPage404/ErrorPage404";
import PersonalData from "./PersonalData/PersonalData";
import Debug from "../../utility/Debug";

const Questionnaire = () => {
    Debug.info('Rendering component %s', 'Questionnaire');

    const match = useRouteMatch();

    return (
        <Switch>
            <Route path={path(ROUTING_PERSONAL_DATA)}>
                <PersonalData />
            </Route>
            <Route path={path(ROUTING_CHAPTER)}>
                <Chapter/>
            </Route>
            <Route path={path(ROUTING_RESULT)} exact>
                <Result/>
            </Route>
            <Route path={`${match.path}`} exact>
                <Redirect to={url(ROUTING_PERSONAL_DATA)}/>
            </Route>

            <Route>
                <ErrorPage404 />
            </Route>
        </Switch>
    )
};

export default Questionnaire;
