import {StyleSheet, Font} from '@react-pdf/renderer';
import fontMontserratRegular from "../../../../../../fonts/Montserrat-Regular.ttf"
import fontMontserratSemiBold from "../../../../../../fonts/Montserrat-SemiBold.ttf"
import fontMontserratItalic from "../../../../../../fonts/Montserrat-Italic.ttf"
import fontMontserratSemiBoldItalic from "../../../../../../fonts/Montserrat-SemiBoldItalic.ttf"

// Register font
Font.register({
    family: 'Montserrat', fonts: [
        {src: fontMontserratRegular},
        {src: fontMontserratSemiBold, fontWeight: 'bold'},
        {src: fontMontserratItalic, fontStyle: 'italic'},
        {src: fontMontserratSemiBoldItalic, fontWeight: 'bold', fontStyle: 'italic'},
    ]
});

const COLOR_BLACK = '#000000';
const COLOR_GREY = '#cecece';
const COLOR_PURPLE = '#67296D';
const BASE_FONT_SIZE = 4;

function getSize(rem) {
    return rem * BASE_FONT_SIZE + 'mm';
}

const offsetLeft = '14pt';


const spacingSizes = [
    '0',
    '5pt',
    '12pt',
    '20pt',
    '30pt',
    '40pt',
]

const spacing = {};

for (let i = 0; i <= spacingSizes.length; i++) {
    spacing['ml' + i] = {
        marginLeft: spacingSizes[i]
    }
    spacing['mr' + i] = {
        marginRight: spacingSizes[i]
    }
    spacing['mt' + i] = {
        marginTop: spacingSizes[i]
    }
    spacing['mb' + i] = {
        marginBottom: spacingSizes[i]
    }    
    spacing['pl' + i] = {
        paddingLeft: spacingSizes[i]
    }
    spacing['pr' + i] = {
        paddingRight: spacingSizes[i]
    }
    spacing['pt' + i] = {
        paddingTop: spacingSizes[i]
    }
    spacing['pb' + i] = {
        paddingBottom: spacingSizes[i]
    }
}

spacing.mlAuto = {
    marginLeft: 'auto'
}

spacing.mrAuto = {
    marginRight: 'auto'
}

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
        color: COLOR_BLACK,
        padding: '38pt',
        paddingTop: '20pt',
        paddingBottom: '71.5pt',
        fontFamily: 'Montserrat',
        fontSize: '10pt',
        position: 'relative'
    },
    chapterSummary: {
        marginBottom: getSize(3),
    },
    chapterSummaryQuestionNumber: {
        fontSize: '10pt',
        minWidth: offsetLeft,
        width: offsetLeft,
    },
    offsetLeft: {
        marginLeft: offsetLeft
    },
    header: {
        marginBottom: '54pt',
    },
    footer: {
        position: 'absolute',
        bottom: '20pt',
        left: '38pt',
        width: '100%',
    },
    pageNumber: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '10pt'
    },
    logoAdhs: {
        width: 'auto',
        height: '52pt',
        marginTop: '8pt',
        marginBottom: '8pt'
    },
    logoMedice: {
        width: 'auto',
        height: '38.46pt',
    },
    conditionsIcon: {
        width: '19pt',
        height: '19pt',
        marginRight: '9pt'
    },
    conditionsLabel: {
        fontSize: '16pt'
    },
    h1: {
        fontSize: '20pt'
    },
    h2: {
        fontSize: '14pt',
    },
    diagnosis: {
        fontSize: '20pt',
    },
    additionalDiagnosis: {
        fontSize: getSize(1.1)
    },
    arztStempel: {
        border: '1pt solid ' + COLOR_GREY,
        borderRadius: '15pt',
        height: '154.5pt',
    },
    colorPurple: {
        color: COLOR_PURPLE
    },
    alignContentEnd: {
        alignContent: 'flex-end'
    },
    alignSelfCenter: {
        alignSelf: 'center'
    },
    alignSelfEnd: {
        alignSelf: 'flex-end'
    },
    flexColumn: {
        flexDirection: 'column'
    },
    flexGrow: {
        flexGrow: 1
    },
    flexRow: {
        flexDirection: 'row'
    },
    fontWeightBold: {
        fontWeight: 'bold'
    },
    justifyContentBetween: {
        justifyContent: 'space-between'
    },
    justifyContentCenter: {
        justifyContent: 'center'
    },
    justifyContentEnd: {
        justifyContent: 'flex-end'
    },
    justifyContentEvenly: {
        justifyContent: 'space-evenly'
    },
    justifyContentStart: {
        justifyContent: 'flex-start'
    },
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    borderBottom: {
        borderBottom: '1pt solid ' + COLOR_GREY
    },
    borderBottomDashed: {
        borderBottom: '1pt dashed ' + COLOR_GREY
    },
    borderTop: {
        borderTop: '1pt solid ' + COLOR_GREY
    },
    borderTopDashed: {
        borderTop: '1pt dashed ' + COLOR_GREY
    },
    flex1: {
        flex: 1
    },
    ...spacing
});

export default styles;