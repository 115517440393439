import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button, Form, Alert} from "react-bootstrap";
import Debug from "../../../utility/Debug";

const MEDICE_LOGIN_URL = 'https://medice-login-test.local';
const FORGOT_PASSWORD_URL = 'https://medice-login-test.local';
const REGISTER_URL = 'https://medice-login-test.local';
const MEDICE_LOGIN_SUCCESS_CODE = 'abcde';
const MEDICE_LOGIN_FAILURE_CODE = 'fghij';

const MediceLogin = ({onLoginSuccess}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();

    function handleUsernameChange(e) {
        setUsername(e.target.value);
        setError(null);
    }

    function handlePasswordChange(e) {
        setPassword(e.target.value);
        setError(null);
    }

    function handleSubmit(e) {
        Debug.info('handleSubmit');

        e.preventDefault();

        if (!formIsValid()) {
            return;
        }

        setError(null);

        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        fetch(MEDICE_LOGIN_URL, {
            method: 'POST',
            body: formData
        }).then(response => response.json())
            .then(data => {
                Debug.info('Success', data);

                if (data.code === MEDICE_LOGIN_SUCCESS_CODE) {
                    onLoginSuccess();
                } else {
                    setError('Bitte überprüfen Sie Ihre Eingaben.');
                }
            })
            .catch((error) => {
                Debug.error('Error', error);
                setError('Fehler bei der Verarbeitung der Anfrage.');
            });
    }

    function formIsValid() {
        return username && username.length > 0 && password && password.length > 0;
    }

    return (
        <p>Medice Login ist noch nicht implementiert.</p>
    )

    return (
        <div className="MediceLogin">
            {error?
                <Alert variant="danger">{error}</Alert>: null
            }

            <Form method="post" action={MEDICE_LOGIN_URL} onSubmit={handleSubmit}>
                <Form.Group controlId="username">
                    <Form.Control
                        value={username}
                        name="username"
                        type="text"
                        onChange={handleUsernameChange}
                        autoFocus
                        autoComplete="name"
                        onFocus={e => e.currentTarget.select()}
                        placeholder="Benutzername"
                    />
                </Form.Group>
                <Form.Group controlId="password" className="mt-3">
                    <Form.Control
                        value={password}
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        onChange={handlePasswordChange}
                        onFocus={e => e.currentTarget.select()}
                        placeholder="Passwort"
                    />
                </Form.Group>

                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <a href={FORGOT_PASSWORD_URL} target="_blank" rel="noopener noreferrer" className="btn btn-link p-0">
                            <small>Passwort vergessen?</small>
                        </a>
                        <br/>
                        <a href={REGISTER_URL} target="_blank" rel="noopener noreferrer" className="btn btn-link p-0">
                            <small>Registrieren</small>
                        </a>
                    </div>
                    <div>
                        <Button type="submit" variant="default" disabled={!formIsValid()}>Anmelden</Button>
                    </div>
                </div>
            </Form>
        </div>
    )
}

MediceLogin.propTypes = {
    onLoginSuccess: PropTypes.func.isRequired
}

export default MediceLogin;