import React from "react";
import "./ProgressBarSections.sass";
import useProgress from "../../../../hooks/useProgress";

function ProgressBarSections() {
    const {sections} = useProgress();
    const {labels, total, current} = sections;

    const width = 100 / total;

    return (
        <div className="ProgressBarSections">
            <div className="progress">
                {labels.map((label, index) => {
                    let classNames = 'progress-bar';
                    if (index === current) {
                        classNames += ' active';
                    }

                    if (index < current) {
                        classNames += ' completed';
                    }

                    return (
                        <div className={classNames} style={{width: width + '%'}} key={index}>
                            <span><span className="d-none d-sm-inline">Kapitel</span> {label}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default ProgressBarSections;