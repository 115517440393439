import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Form} from "react-bootstrap";
import Debug from "../../../utility/Debug";
import useQuery from "../../hooks/useQuery";

const DOC_CHECK_ID = process.env.NODE_ENV !== 'production' ? '2000000016684' : '2000000016690';
const DOC_CHECK_LOGIN_URL = 'https://login.doccheck.com';
const DOC_CHECK_TOKEN = '4rn1s8uqRm0m21gaYs6VTJtfl9kAw4Pq';
const FORGOT_PASSWORD_URL = 'https://www.doccheck.com/reset-password-request'
const REGISTER_URL = 'https://www.doccheck.com/register'

const DocCheckLogin = ({onLoginSuccess}) => {
    const query = useQuery();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    function handleUsernameChange(e) {
        setUsername(e.target.value);
    }

    function handlePasswordChange(e) {
        setPassword(e.target.value);
    }

    function formIsValid() {
        return username && username.length > 0 && password && password.length > 0;
    }

    useEffect(() => {
        if (query.get('dc_timestamp') && query.get('dc_token') === DOC_CHECK_TOKEN) {
            Debug.trace('Checking DocCheck Login');

            const currentTimestamp = Math.floor(Date.now() / 1000);
            const queryTimestamp = parseInt(query.get('dc_timestamp'));

            if (Math.abs(queryTimestamp - currentTimestamp) <= 300) {
                onLoginSuccess();
            }
        }
    }, [onLoginSuccess, query]);

    return (
        <Form method="post" action={DOC_CHECK_LOGIN_URL}>
            <Form.Group controlId="username">
                <Form.Control
                    value={username}
                    name="username"
                    type="text"
                    onChange={handleUsernameChange}
                    autoFocus
                    autoComplete="name"
                    onFocus={e => e.currentTarget.select()}
                    placeholder="Benutzername"
                />
            </Form.Group>
            <Form.Group controlId="password" className="mt-3">
                <Form.Control
                    value={password}
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={handlePasswordChange}
                    onFocus={e => e.currentTarget.select()}
                    placeholder="Passwort"
                />
            </Form.Group>

            <input type="hidden" name="login_id" value={DOC_CHECK_ID}/>

            <input type="hidden" name="loginform" value="doccheck"/>

            <div className="d-flex justify-content-between mt-4">
                <div>
                    <a href={FORGOT_PASSWORD_URL} target="_blank" rel="noopener noreferrer" className="btn btn-link p-0">
                        <small>Passwort vergessen?</small>
                    </a>
                    <br/>
                    <a href={REGISTER_URL} target="_blank" rel="noopener noreferrer" className="btn btn-link p-0">
                        <small>Registrieren</small>
                    </a>
                </div>
                <div>
                    <Button type="submit" variant="default" disabled={!formIsValid()}>Anmelden</Button>
                </div>
            </div>
        </Form>
    )
}

DocCheckLogin.propTypes = {
    onLoginSuccess: PropTypes.func.isRequired
}

export default DocCheckLogin;