import Debug from "./Debug";
import {getQuestionnaire} from "./QuestionnaireProvider";

export function isFirstQuestionInChapter(chapterIndex, questionIndex) {
    return parseInt(questionIndex) === 0;
}

export function isLastQuestionInChapter(chapterIndex, questionIndex) {
    return parseInt(questionIndex) === getChapter(chapterIndex).questionsCount - 1;
}

export function getChapterById(id) {
    return getChapter(getChapterIndexFromId(id));
}

export function getChapterIndexFromId(id) {
    Debug.trace('getChapterIndexFromId: %s', id)
    const questionnaire = getQuestionnaire();

    for (let i = 0; i < questionnaire.chapters.length; i++) {
        if (questionnaire.chapters[i].id === id) {
            return i;
        }
    }

    Debug.error('Cannot get chapter from id: %s', id)
    throw new Error('Cannot get chapter from id: ' + id);
}

export function areConditionsMet(chapterIndex, answers) {
    Debug.trace('areConditionsMet', chapterIndex, answers)

    const chapter = getChapter(chapterIndex);
    const answersForChapter = answers.chapters[chapterIndex];
    if (!answersForChapter) {
        throw new Error('Chapter not found in questionnaireAnswers');
    }

    // Check if all questions are answered. If not => Conditions not met.
    for (let i = 0; i < chapter.questions.length; i++) {
        if (!answersForChapter.questions[i] || answersForChapter.questions[i].answer === null) {
            Debug.info('Question not answered', chapter, answersForChapter, i);
            return false;
        }
    }

    // Check if answers reach minimum value.
    const {minimumValue} = chapter.conditions;

    if (minimumValue) {
        Debug.trace('Checking minimum value');
        let value = 0;
        for (let i = 0; i < chapter.questions.length; i++) {
            const question = chapter.questions[i];
            const choice = question.choices[answersForChapter.questions[i].answer];

            if (!choice) {
                Debug.error('Choice does not exist', chapter, answersForChapter, i);
                throw new Error('Choice does not exist');
            }

            value += choice.value;
        }

        Debug.trace('value: %s, minimumValue: %s', value, minimumValue);
        if (value >= minimumValue) {
            return true;
        }
    }

    return false;
}

export function getChapter(chapterIndex) {
    const questionnaire = getQuestionnaire();

    if (!questionnaire.chapters[chapterIndex]) {
        throw new Error('Chapter not found');
    }

    return questionnaire.chapters[chapterIndex];
}

export function getQuestion(chapterIndex, questionIndex) {
    const questionnaire = getQuestionnaire();

    if (!questionnaire.chapters[chapterIndex]) {
        throw new Error('Chapter not found');
    }
    if (!questionnaire.chapters[chapterIndex].questions[questionIndex]) {
        throw new Error('Question not found');
    }

    return questionnaire.chapters[chapterIndex].questions[questionIndex];
}

export function answerIsValid(selectedChoice, question) {
    return selectedChoice !== null && !isNaN(selectedChoice) && selectedChoice >= 0 && selectedChoice < question.choices.length;
}

export function isChapterA(chapter) {
    const chapterIds = [
        "A.2.intro",
        "A.2.integrated",
        "A.2.prefilled"
    ];

    return chapterIds.includes(chapter.id);
}