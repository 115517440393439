import React from "react";
import {Redirect, Route, Switch, useParams} from "react-router-dom";
import Question from "./Question/Question";
import Summary from "./Summary/Summary";
import ErrorPage404 from "../../ErrorPage404/ErrorPage404";
import Debug from "../../../utility/Debug";
import {path, ROUTING_CHAPTER, ROUTING_QUESTION, ROUTING_QUESTION_FROM_SUMMARY, ROUTING_SUMMARY, url} from "../../../utility/Routing";

const Chapter = () => {
    Debug.info('Rendering component %s', 'Chapter');

    const {chapterIndex} = useParams();

    return (
        <Switch>
            <Route path={path(ROUTING_SUMMARY)} exact>
                <Summary />
            </Route>
            <Route path={path(ROUTING_QUESTION)} exact>
                <Question />
            </Route>
            <Route path={path(ROUTING_QUESTION_FROM_SUMMARY)} exact>
                <Question />
            </Route>
            <Route path={path(ROUTING_CHAPTER)} exact>
                <Redirect to={url(ROUTING_QUESTION, {chapterIndex: chapterIndex, questionIndex: 0})} />
            </Route>

            <Route>
                <ErrorPage404 />
            </Route>
        </Switch>
    );
}

export default Chapter;
